import React from 'react';
import { Select } from 'antd';

export function getOptions(options = [], t) {
  return options.map((option) => (
    <Select.Option key={option.value} disabled={!!option.disabled}>
      {t ? t(option.text) : option.text}
    </Select.Option>
  ));
}

export function formatOptions(data, showDisplayName = false) {
  return data.map(({
    uuid,
    name,
    displayName
  }) => ({
    text: showDisplayName ? displayName : name,
    key: uuid,
    value: uuid
  }));
}

export function formatListOptionsDataForDropdown(data, fieldName) {
  return data.map((
    item
  ) => ({
    text: `teamsForm:${fieldName}.value.${item}`,
    key: item,
    value: item
  }));
}

// Remove object from options array
export function removeDuplicates(array) {
  const uniqueKeys = Array.from(new Set(array.map((item) => item.key)));
  return uniqueKeys.map((key) => array.find((item) => item.key === key));
}
